<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
@import "~@/assets/css/index.css";
@import "~@/assets/css/list.css";
</style>

<style scoped>
/deep/ .outerCotent p,
/deep/ #pdf p {
  margin: 0 !important;
  background-color: transparent !important;
}

/deep/ #nobgColor p {
  background-color: transparent !important;
}

/deep/ .el-input__inner {
  width: 50%;
  height: 35px;
}

body {
  font-size: 16px;
}

.cjd_title {
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: unset;
  flex: 1;
}

.checkImg2 {
  right: 51%;
  height: 30px;
  top: 8px;
  height: 30px;
}

.form-control {
  padding: 5px 10px;
  border: unset;
  height: unset;
  display: flex;
  align-items: center;
}

.less-width .w50 {
  width: 40%;
}

.less-width .w50:nth-child(2n) {
  width: 60% !important;
}

.guding-col .msgLabel:nth-child(n-1) {
  border-bottom: unset;
}


.style-right {
  background-size: cover;
  /*min-height: 500px;*/
  font-size: 18px;
  padding: 30px 25px 30px 80px;
  background-repeat: no-repeat;
}

.score-list .msgName {
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
<script>
import Header from "./index-header.vue";
import Footer from "./index-footer.vue";
import Navbar from "./navbar.vue";
import grade from "@/components/grade/grade";
import gradePrint from "@/components/grade/gradePrint";
import {getcode} from "@/api/index.js";
import $ from "jquery";

import {getgrade, getLibraryInfo, getTemplateInfo, cjSearch,getCjImg} from "@/api/grade";
import {format} from "@/libs/common";

/**
 * 报名信息提交
 */
export default {
  components: {
    Header,
    Navbar,
    Footer,
    grade,
    gradePrint
  },
  data() {
    return {
      title: "成绩查询列表",
      formData: {
        ksxm: "",
        zjhm: "",
        // zkzh: "",
        // zkzyxz: false,
        code: "",
        uuid: "",
        cjkid: "",
      },
      cxtj: ["ksxm", "zjhm"],
      query: "",
      examInfo: "",
      scoreData: {},
      gradeData: [
        "cj",
        "cjcllx",
        "dah",
        "jmcj",
        "km",
        "kmmc",
        "kmtg",
        "rdrq",
        "wjlbm",
        "wjrdiy",
        "ycl",
        "zkzh",
      ],
      gradeDataList: [],
      library: {},
      bgimgUrl: "",
      imgUrl: "",
      list1: [],
      sfxszp: false,
      sfhxbj: false,
      moduleType: "",
      pageNum: 0,
      pageHeight: 1103,
      pdfHeight: 0,
      pdfNum: 1,
      totalPage: 1,
      TemplateInfo: {},
      canfh: false,
      islogin: "",
      imgCode: {
        uuid: "",
        img: "",
        code: "",
      },
      printShow: false,
    };
  },
  methods: {
    //下载成绩单
    downloadScore() {
      // this.$router.push({
      //   path: "/gradePrint",
      //   query: {
      //     bmh: this.query.bmh,
      //     cjkid: this.query.sid,
      //   },
      // });

    },
    //成绩复核
    recheck() {
      this.$router.push({
        path: "/scoreCheck",
        query: {
          bmh: this.query.bmh,
          cjkid: this.query.sid,
        },
      });
    },
    dealData(data, index) {
      let obj = {};
      if (data["cj_" + index] != null) {
        this.gradeData.map((key) => {
          obj[key] = data[key + "_" + index];
        });
        this.gradeDataList.push(obj);
        this.dealData(data, index + 1);
      }
    },
    getLibrary() {
      getLibraryInfo(this.query.sid).then((res) => {
        if (res.status) {
          res.data.fhkssj = format(res.data.fhkssj, "yyyy-MM-dd");
          res.data.fhjssj = format(res.data.fhjssj, "yyyy-MM-dd");
          if (
              new Date(res.data.fhkssj) < new Date() &&
              new Date(res.data.fhjssj) > new Date() &&
              res.data.sfyxfh == 1
          ) {
            this.canfh = true;
          }
          this.library = res.data;
          /*if (res.data.cjbjwj) {
            this.getFileUrl(res.data.cjbjwj, true).then((res) => {
              if (res.status) {
                this.bgimgUrl = res.data;
              }
            });
          }*/
          if (res.data.mbh) {
            this.getgradeTemplate(res.data.mbh);
          }

        }
      });

    },
    getGrade(data) {
      cjSearch(data).then((res) => {
        if (res.status) {
          if (res.data) {
            this.printShow = true;

            if (res.data.cscx) {
              this.canfh = true
            }
            this.dealData(res.data, 1);
            this.scoreData = res.data;
            let url = ''
            if (this.library.ksbmbh) {
              url = "bm_" + this.library.ksbmbh + "_" + res.data.zjhm;
            } else {
              url = "cjk_" + res.data.cjkid + "_" + res.data.zjhm;
            }
            if (this.TemplateInfo.sfxszp) {
              this.getFileUrl(url, true).then((res) => {
                if (res.status) {
                  this.imgUrl = res.data;
                }
                this.$nextTick(() => {
                  this.pageSign();
                  $(".guding-img img").attr("src", this.imgUrl);
                  $("#gradePrint").hide();
                  this.toPdfHtml();

                });
              });
            } else {
              this.$nextTick(() => {
                this.pageSign();
                $("#gradePrint").hide();
                this.toPdfHtml();

              });
            }
          }
        }

      })
    },
    getgradeTemplate(mbh) {
      getTemplateInfo(mbh).then((res) => {
        if (res.status) {
          this.TemplateInfo = res.data
          this.list1 = res.data.printTemplateFieldBeanList;
          this.sfxszp = res.data.sfxszp;
          this.sfhxbj = res.data.sfhxbj;
          this.moduleType = 0;
          if (this.sfhxbj) {
            this.moduleType = 1;
            this.pageHeight = Math.floor((1103 / 297) * 210);
          }
          this.searchBg(mbh)
        }
      });
    },
    searchBg(mbh) {
      if (mbh) {
        getCjImg(mbh).then((res) => {
          if (res.status) {
            this.bgimgUrl = res.data;

            // this.getGrade(data);
          }
        });
        return
      }
      // this.getGrade(data);

    },
    pageSign() {
      let height = $(".outerCotent").height();
      this.pageNum = parseInt(height / this.pageHeight);
    },
    toPdfHtml() {
      $("#pdf").empty();
      $("#pdf").append(
          "<div class='pdf' id='pdf1' page='1' style='height:" +
          this.pageHeight +
          "px;position:relative'></div>"
      );
      // 成绩单内容
      $("#gradePrint")
          .children()
          .children()
          .each((i, item) => {
            this.addItem(item, $(item).outerHeight(true), this.bgimgUrl);
          });
    },

    addItem(item, height, imgurl) {
      let oldNHum = this.pdfNum;
      let paddingHeight = 0;
      height = Number(height);
      // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
      if (
          this.pdfHeight > 0 &&
          parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
          parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
      ) {
        // pdf页数加1
        this.totalPage += 1;
        this.pdfNum += 1;
        this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
        $("#pdf").append(
            "<div class='pdf' id='pdf" +
            this.pdfNum +
            "' page='" +
            this.pdfNum +
            "'style='height:" +
            this.pageHeight +
            "px;position:relative'></div>"
        );

      }
      this.pdfHeight += height;
      // 将内容放入div中
      if ($(item)[0].id == "scoreBottom" && this.pdfNum == oldNHum) {

        $(item).css("position", "absolute");
        $(item).css("bottom", "0");
      }
      $(item)
          .clone()
          .appendTo($("#pdf" + this.pdfNum));
      // $(".pdf").css("border", "1px #000000 solid")
      $(".pdf").css("padding", "1px 25px 0 45px");
      if (imgurl) {
        $(".pdf").css("background-image", "url(" + imgurl + ")");
      }
    },
    submit() {
      let data = this.formData;
      for (const key of this.cxtj) {
        if (!data[key]) {
          this.$message("信息未完善，请继续填写");
          return false;
        }
      }
      if (!data.code) {
        this.$message("请填写验证码");
        return false;
      }
      this.getGrade(data)


    },
    // 获取图片验证码
    getImageCode: function () {
      var _this = this;
      getcode().then((res) => {
        if (res.status) {
          _this.imgCode = res.data;
          _this.formData.uuid = res.data.uuid;
        }
      });
    },
  },
  created() {
    this.query = this.$route.query;
    this.formData.cjkid = this.query.sid;
    this.getLibrary();
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
      this.$alert(
          "为了更好的使用本系统，我们推荐您使用谷歌Chrome浏览器或360极速模式！",
          "提示",
          {
            confirmButtonText: "确定",
            callback: () => {
              window.location.href = "/browser";
            },
          }
      );
    }
  },
  mounted() {
    this.getImageCode();
  },
};
</script>

<template>
  <div>
    <Header></Header>
    <div class="main">
      <div class="wp">
        <Navbar></Navbar>
        <div class="ticketPrint" style="min-height: 70vh">
          <div class="row">
            <div class="col-lg-12" v-if="!printShow">
              <div class="card">
                <div
                    class="card-body d-flex"
                    style="
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    "
                >
                  <h4 class="card-title my-3">
                    <span
                        class="font-size-20"
                        style="margin: 0 auto; font-weight: bold"
                    >{{ library.cjdmc }}</span>
                  </h4>
                  <div class="col-sm-12" style="margin: 0 auto">
                    <el-form ref="form" :model="formData">
                      <div class="input-group row no-gutters">
                        <label
                            for="Username"
                            class="col-2 justify-content-center d-flex align-items-center text-center"
                        >姓&emsp;&emsp;名</label>
                        <el-form-item prop="xm" class="">
                          <el-input
                              id="Username"
                              type="text"
                              class="form-control"
                              v-model="formData.ksxm"
                          />
                        </el-form-item>
                      </div>
                      <div class="input-group row no-gutters">
                        <label
                            for="idCard"
                            class="col-2 justify-content-center d-flex align-items-center text-center"
                        >证件号码</label>
                        <el-form-item prop="sfzjh" class="">
                          <el-input
                              id="idCard"
                              type="text"
                              class="form-control"
                              v-model="formData.zjhm"
                          />
                        </el-form-item>
                      </div>
                      <div class="input-group row no-gutters">
                        <label
                            for="condition"
                            class="col-2 justify-content-center d-flex align-items-center text-center"
                        >验&ensp;证&ensp;码</label>
                        <el-form-item class="" prop="code">
                          <el-input
                              id="condition"
                              type="text"
                              class="form-control"
                              v-model="formData.code"
                          />
                          <img
                              :src="imgCode.img"
                              class="check-img checkImg2"
                              @click="getImageCode"
                          />
                        </el-form-item>
                      </div>
                      <div class="input-group row no-gutters">
                        <label
                            for="condition"
                            class="col-2 justify-content-center d-flex align-items-center text-center"
                        >
                          查&emsp;&emsp;询</label>
                        <el-form-item class="" style="padding: 5px 10px">
                          <button
                              type="button"
                              class="btn btn-info"
                              style="width: 255px"
                              @click="submit"
                          >
                            查询成绩
                          </button>
                        </el-form-item>
                      </div>
                    </el-form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="card" v-if="printShow">
                <div class="card-body ">
                  <div
                      id="pdf"
                      style="margin: 0 auto"
                      :style="{ width: moduleType == 1 ? '1103px' : '780px' }"
                  ></div>
                  <div class="outerCotent mt-3 score-list mb-3 score-print" id="">
                    <grade-print
                            :list="list1"
                            :grade="scoreData"
                            :library="library"
                            :zpxs="sfxszp"
                            :sfhxbj="sfhxbj"
                            :TemplateInfo="TemplateInfo"
                    ></grade-print>
                    <div class="mt-3 flexList">

                        <span class="grey-time" v-if="canfh"
                        >（成绩复核提交时间：{{ library.fhkssj }}至{{ library.fhjssj }}）</span>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
