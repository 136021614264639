<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
<style scoped>
  html,body{
    font-size: 16px;
  }

  .w-99 {
    width: 99%;
  }

  .w100 {
    width: 100%;
  }

  .w50 {
    width: 50%;
  }

  .style-set-box {
    border-bottom: unset;
  }

  .less-width .w50 {
    width: 40%;
  }

  .less-width .w50:nth-child(2n) {
    width: 60% !important;
  }

  .guding-top {
    width: 80%;
  }

  .exam-form {
    padding-left: 20px;
  }

  .top-height {
    max-height: 160px;
    line-height: 160px;
  }

  .msgLabel {
    color: #000000;
  }

  .cjd_title {
    width: 100%;
    /* height: 50px; */
    /* line-height: 50px; */
    font-size: 24px;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
    color: #000000;
    /* line-height: normal; */
  }

  .bottom-info {
    /* max-height: 100px; */
    padding-left: 20px;
  }

  .guding-col {
    flex-wrap: wrap;
    box-sizing: border-box;
  }

  .guding-col .msgLabel:nth-child(n-1) {
    border-bottom: unset;
  }

  outerCotent {
    min-width: unset;
  }
  .guding-img {
    width: 20%;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: unset;
  }

  .style-right {
    background-size: 100% 100%;
    min-height: 500px;
    font-size: 18px;
    padding: 0 25px 0 45px ;
    background-repeat: no-repeat;
  }
  .msgLabel {
    border-right: unset;
  }
  .style-name {
    font-weight: 600;
    font-size: 14px;
  }
  /* 新增防内容格式变形 */
.msgLabel {
  align-items: flex-start;
  min-height: 45px;
  height: initial
}
.style-name {
  flex: none;
}
</style>

<script>
import $ from "jquery";
import { format } from "@/libs/common";
import { getCjImg} from "@/api/grade";
import { noChange } from "@/assets/js/nochange.js"

export default {
  name: "clone-on-control",
  display: "Clone on Control",
  instruction: "Press Ctrl to clone element from list 1",
  order: 4,
  props: {
    list: Array,
    grade: Object,
    library: Object,
    TemplateInfo: {},
    zpxs: Boolean,
    sfhxbj: Boolean,
  },
  components: {
  },
  data() {

    return {
      list2: [],
      list3: [],
      sid: "",
      zjhm: "",
      form: {},
      isShowZp: true,
      mid: "",
      mbh: "",
      mbmc: "",
      mbmc2: "",
      imgId: "",
      bgimgUrl: "",
      ksbmbh: "",
      listChoose: [],
      examineeData: {},
      gradeData: [
        "cj",
        "cjcllx",
        "dah",
        "jmcj",
        "km",
        "kmmc",
        "kmtg",
        "rdrq",
        "wjlbm",
        "wjrdiy",
        "ycl",
        "zkzh",
      ],
      gradeDataList: [],
      imgUrl: "",
      moduleType: "",
      pageNum: 0,
      pageHeight: 1103,
      pdfHeight: 0,
      pdfNum: 1,
      totalPage: 1
    };
  },
  methods: {
    //下载成绩单
    downloadScore() {
      this.ExportSavePdf("成绩单", "pdf", this.moduleType);
      // this.$confirm("请选择横版打印还是竖版?", "提示", {
      //   confirmButtonText: "横版",
      //   cancelButtonText: "竖版",
      //   type: "warning",
      // })
      //   .then(() => {
      //     this.ExportSavePdf("成绩单", "gradePrint", this.moduleType);
      //   })
      //   .catch(() => {
      //     this.ExportSavePdf("成绩单", "gradePrint", this.moduleType);

      //   });
    },
    getLibrary() {
      this.library.fhkssj = format(this.library.fhkssj, "yyyy-MM-dd");
      this.library.fhjssj = format(this.library.fhjssj, "yyyy-MM-dd");
      if (
          new Date(this.library.fhkssj) < new Date() &&
          new Date(this.library.fhjssj) > new Date() &&
          this.library.sfyxfh == 1
      ) {
        this.library.canfh = true;
      }
      this.getgradeTemplate();
    },
    getGrade() {
      let res = {data: this.grade}
      this.dealData(res.data, 1);
      this.examineeData = res.data;
      let url = "";
      if (this.library.ksbmbh) {
        url = "bm_" + this.library.ksbmbh + "_" + res.data.zjhm;
      } else {
        url = "cjk_" + res.data.cjkid + "_" + res.data.zjhm;
      }
      if (this.TemplateInfo.sfxszp) {
        this.getFileUrl(url, true).then((res) => {
          if (res.status) {
            this.imgUrl = res.data;
          }
          this.$nextTick(() => {
            this.pageSign();
            $("#gradePrint").hide();
            this.toPdfHtml();

          });
        });
      } else {
        this.$nextTick(() => {
          this.pageSign();
          $("#gradePrint").hide();
          this.toPdfHtml();

        });
      }
    },
    getgradeTemplate() {

      let res = {data: this.TemplateInfo}
      this.list2 = [];
      this.list3 = [];
      let mbh=this.TemplateInfo.mid
   if(mbh){
     getCjImg(mbh).then((res) => {
       if (res.status) {
         this.bgimgUrl = res.data;
         this.getGrade();
       }
     });
   }

  /*    if (res.data.cjbjwj) {
        this.getFileUrl(res.data.cjbjwj, true).then((res) => {
          if (res.status) {
            this.bgimgUrl = res.data;
            this.getGrade();
          }
        });
      }*/
      else{
        this.getGrade();
      }
      if (!res.data.sfxszp) {
        this.isShowZp = false;
        this.$nextTick(() => {
          this.hiddenZp();
        });
      } else {
        this.isShowZp = true;
        this.hiddenZp();
      }
      if (res.data.sfhxbj) {
        this.moduleType = 1;
        this.pageHeight = Math.floor((1103 / 297) * 210);
        $("#gradePrint").css("width", "1103px");
        // $(".card").css("width", '1103px')
        let height = Math.floor((1103 / 297) * 210);
        $("#gradePrint").css("height", height + "px");
      } else {
        this.moduleType = 0;
        $("#gradePrint").css("width", "780px");
        // $(".card").css("width", '780px')
        let height = Math.floor((780 / 210) * 297);
        $("#gradePrint").css("height", height + "px");
      }
      this.list.forEach((item) => {
        item.zddm = item.zddm.replace("_", "");
        if (res.data.sfxszp) {
          if (item.zdfl == 1) {
            this.list2.push(item);
          } else {
            this.list3.push(item);
          }
        } else {
          this.list3.push(item);
        }
      });


    },
    dealData(data, index) {
      let obj = {};
      if (data["cj_" + index] != null) {
        this.gradeData.map((key) => {
          obj[key] = data[key + "_" + index];
        });
        this.gradeDataList.push(obj);
        this.dealData(data, index + 1);
      }
    },

    isHidden() {
      this.isShowZp = !this.isShowZp;
      this.hiddenZp();
    },
    //显示隐藏照片
    hiddenZp() {
      if (this.isShowZp == false) {
        $(".guding-top").css("width", "99%");
        $(".guding-top").addClass("border-right");
        $(".guding-img").css("display", "none");
        $(".guding-col").addClass("less-width");
      } else {
        $(".guding-img").css("display", "flex");
        $(".guding-top").css("width", "80%");
        $(".guding-top").removeClass("border-right");
        $(".guding-col").removeClass("less-width");
      }
    },
    pageSign() {
      let height = $(".outerCotent").height();
      this.pageNum = parseInt(height / this.pageHeight);
    },
    toPdfHtml() {
      $("#pdf").empty();
      $("#pdf").append(
        "<div class='pdf' id='pdf1' page='1' style='height:" +
          this.pageHeight +
          "px;position:relative'></div>"
      );
      // $("#pdf1").append(
      //   "<div class='page' style='height:30px'>" +
      //     this.totalPage +
      //     "/<span></span></div>"
      // );
      // 成绩单内容
      $("#gradePrint")
        .children()
        .children()
        .each((i, item) => {
          this.addItem(item, $(item).outerHeight(true), this.bgimgUrl);
        });
    },

    addItem(item, height, imgurl) {
      let oldNHum = this.pdfNum;
      let paddingHeight = 0;
      height = Number(height);
      // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
      if (
        this.pdfHeight > 0 &&
        parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
          parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
      ) {
        // pdf页数加1
        this.totalPage += 1;
        this.pdfNum += 1;
        this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
        $("#pdf").append(
          "<div class='pdf' id='pdf" +
            this.pdfNum +
            "' page='" +
            this.pdfNum +
            "'style='height:" +
            this.pageHeight +
            "px;position:relative'></div>"
        );
        // $("#pdf" + this.pdfNum).append(
        //   "<div class='page' style='height:30px'>" +
        //     this.totalPage +
        //     "/<span></span></div>"
        // );
      }
      this.pdfHeight += height;
      // 将内容放入div中
      if ($(item)[0].id == "scoreBottom" && this.pdfNum == oldNHum) {
        $(item).css("position", "absolute");
        $(item).css("bottom", "0");
      }
      $(item)
        .clone()
        .appendTo($("#pdf" + this.pdfNum));
      // $(".pdf").css("border", "1px #000000 solid")
      $(".pdf").css("padding", "0 25px 0 45px");
      if (imgurl) {
        $(".pdf").css("background-image", "url(" + imgurl + ")");
      }
    },
  },

  created() {
    this.query = this.$route.query;
    this.getLibrary();
    this.$nextTick(() => {
      // noChange()
    });

  },
};
</script>
<template>
      <div class="card-body">
        <div
          id="pdf"
          style="margin: 0 auto"
          :style="{ width: moduleType == 1 ? '1103px' : '780px' }"
        ></div>
        <div class="outerCotent mt-3 score-print">
          <div
            class="style-set-box h-100 d-flex align-items-center justify-content-center"
          >
            <el-row class="border" id="gradePrint">
              <el-col
                :span="24"
                class="style-right h-100"
                ref="main"
                id="mians"
                :style="{ backgroundImage: 'url(' + bgimgUrl + ')' }"
                style="min-height: 600px; position: relative"
              >
                <div wx class="w-100 cjd_title" style="white-space: pre-wrap">
                  {{ library.cjdmc }}
                </div>
                <div class="w-100">
                  <div
                    v-if="library.cjdzysx"
                    class=" text-dark"
                    style="
                      padding-left: 20px;
                      white-space: pre-wrap;
                    "
                  >
                    {{ library.cjdzysx }}
                  </div>
                </div>
                <div class="mt-3 exam-form" style="max-height: 80%">
                  <el-row
                    style=""
                    class="border-diff top-height"
                    v-if="list2.length != 0"
                  >
                    <el-col class="guding-top">
                      <div
                        ref="list1Container"
                        class="flexList guding-col"
                        :list="list2"
                        group="people"
                        style="min-height: 40px"
                      >
                        <div
                          class="flexList msgLabel"
                          :class="[
                            'w' + element.zdbl,
                            element.txlx == 'textarea' ? 'msgtextarea' : '',
                          ]"
                          v-for="element in list2"
                          :key="element.sid"
                        >
                          <div class="style-name mr-2">
                            {{ element.zdymc ? element.zdymc : element.zdmc }}：
                          </div>
                          <div class="style-val flexList">
                            {{ examineeData[element.zddm] }}
                          </div>
                        </div>
                      </div>
                    </el-col>
                    <el-col class="guding-img">
                      <img
                        :src="imgUrl"
                        alt=""
                        style="width: 120px; height: 150px"
                      />
                    </el-col>
                  </el-row>
                  <el-row>
                    <div
                      style="min-height: 40px; width: 100%; flex-wrap: wrap"
                      class="flexList fs-xs less-width"
                      :list="list3"
                      group="people"
                    >
                      <div
                        class="flexList msgLabel"
                        :class="[
                          'w' + element.zdbl,
                          element.txlx == 'textarea' ? 'msgtextarea' : '',
                        ]"
                        v-for="element in list3"
                        :key="element.sid"
                      >
                        <div class="style-name mr-2">
                          {{ element.zdymc ? element.zdymc : element.zdmc }}：
                        </div>
                        <div class="style-val flexList">
                          {{ examineeData[element.zddm] }}
                        </div>
                      </div>
                    </div>
                  </el-row>
                </div>
                <div v-if="library.cjdbzxx" class="w-100 mb-3" id="scoreBottom">
                  <div
                    class="bottom-info text-dark"
                    style="
                      padding-left: 20px;
                      white-space: pre-wrap;
                    "
                  >
                    {{ library.cjdbzxx }}
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="mt-3">
          <button
            type="button"
            class="btn btn-info flexList h30"
            @click="downloadScore"
          >
            <img
              src="@/assets/images/person/file-pdf.png"
              class="mr-2"
            />下载电子成绩单
          </button>
        </div>
      </div>

    <!-- 弹窗开始 -->

    <!-- 弹窗结束-->
</template>
